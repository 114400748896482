import type { RichTextContent } from '@beamery/component-rich-text';

export const TEXT_ELEMENT_MAX_LENGTH = 250;

interface BaseElement {
  id: string;
}

interface BlockElement extends BaseElement {
  required: null;
  field: null;
}

export type ElementValue = string | string[] | null | undefined;

interface InputElement extends BaseElement {
  required: boolean;
  field: string;
  value?: ElementValue;
}

export interface ImageElement extends BlockElement {
  type: 'image';
  meta: {
    alt: string;
    src?: string;
    filename?: string;
  };
}

export interface RichTextElement extends BlockElement {
  type: 'richtext';
  meta: {
    content: RichTextContent;
  };
}

export interface TextElement extends InputElement {
  type: 'text';
  meta: {
    label: string;
  };
}

export interface MultiTextElement extends InputElement {
  type: 'multiText';
  meta: {
    label: string;
  };
}

export interface EmailElement extends InputElement {
  type: 'email';
  meta: {
    label: string;
    messages?: {
      invalidFormatMessage?: string;
    };
  };
}

export interface PhoneElement extends InputElement {
  type: 'phone';
  meta: {
    label: string;
  };
}

export interface TypeaheadElement extends InputElement {
  type: 'typeahead';
  meta: {
    label: string;
    multi: boolean;
    source: 'location' | 'universities';
  };
}

export interface SelectElement extends InputElement {
  type: 'select';
  meta: {
    label: string;
    multi: boolean;
    options: { label: string; value: string }[];
    sortOptionsAlphabetically?: boolean;
  };
}

export interface DateElement extends InputElement {
  type: 'date';
  meta: {
    label: string;
  };
}

export interface FileElement extends InputElement {
  type: 'file';
  meta: {
    label: string;
    sizeLimit: number;
    acceptedTypes: string[];
    purpose?: 'resume';
    messages?: {
      uploadGuidanceMessage?: string;
      dragAndDropText?: string;
      uploadButton?: string;
      sizeLimitLabel?: string;
      sizeLimitMessage?: string;
      fileTypesLabel?: string;
      fileTypesMessage?: string;
      loadingLabel?: string;
      removeFileLabel?: string;
    };
  };
}

export interface DataProtectionElement extends BlockElement {
  type: 'dataProtection';
  meta: {
    statement?: {
      id: string | null;
    };
  };
  value?: boolean;
}

export interface ButtonElement extends BlockElement {
  type: 'button';
  meta: {
    label: string;
    action: 'submit' | 'reset';
  };
}

export interface DividerElement extends BlockElement {
  type: 'divider';
  meta: null;
}

export interface CompanyLogoElement extends BlockElement {
  type: 'companyLogo';
  meta: null;
}

export interface SocialIconsElement extends BlockElement {
  type: 'socialIcons';
  meta: null;
}

export type FormInputElement =
  | TextElement
  | MultiTextElement
  | EmailElement
  | PhoneElement
  | TypeaheadElement
  | SelectElement
  | DateElement
  | FileElement;

export type FormBlockElement =
  | ImageElement
  | RichTextElement
  | DataProtectionElement
  | ButtonElement
  | DividerElement
  | CompanyLogoElement
  | SocialIconsElement;

export type FormElement = FormInputElement | FormBlockElement;
export type PageType = 'form' | 'success';
export type SubmitAction = 'proceed' | 'alert' | 'login';

export interface Page {
  id: string;
  title: string;
  elements: FormElement[];
  pageType: PageType;
  onSubmit: SubmitAction;
}

interface CookieConsentApiKeys {
  events: string;
  pages: string;
  flows: string;
}

export interface CookieConsent {
  enabled: boolean;
  apiKeys: CookieConsentApiKeys;
  cdn: string;
  provider: string;
  settingsButtonCss: string | null;
  test: boolean;
}

export interface PublicFormCompliance {
  title: string;
  consentRequestIntroductionStatement: string;
  consentQuestion: string;
  privacyPolicyUrl: string;
  showDataEnrichmentLink: boolean;
  privacyPolicyText: string;
  dataEnrichmentLinkText: string | null;
  version: 1 | 2;
}

export interface SavedFormCompliance extends PublicFormCompliance {
  id: string | null;
  isDefault: boolean;
  name: string | null;
}

export interface FormSettingsBase {
  slug: string;
  externalName: string;
  useCustomBranding: boolean;
  customBrandName?: string;
  customBrandLogoUrl?: string;
  authenticated?: boolean;
}

export interface SavedFormSettings extends FormSettingsBase {
  internalName: string;
  slug: string;
}

export interface FormUserProfile {
  id: string;
}

export interface SocialMedia {
  twitter?: string;
  youtube?: string;
  facebook?: string;
  linkedin?: string;
  instagram?: string;
  glassdoor?: string;
  wechat?: string;
}

export interface BaseFormCompanySettings {
  accentColor: string | null;
  companyLogoUrl: string;
  companyName: string;
  companyId: string;
  companySlug: string;
  cookieSettings: CookieConsent;
  gaKey: string | null;
  vanityUrls: string[] | null;
  customFontUrl: string | null;
  customFontFamilyName: string | null;
  socialMedia: SocialMedia | null;
}

export interface PublicFormCompanySettings extends BaseFormCompanySettings {
  compliances: PublicFormCompliance[];
  gtmKey: string | null;
  recaptchaEnabled: boolean;
}

export interface SavedFormCompanySettings extends BaseFormCompanySettings {
  compliances: SavedFormCompliance[];
}

export interface FlexibleOptions {
  singleSelectPlaceholder?: string | null;
  singleSelectFilterText?: string | null;
  multiSelectPlaceholder?: string | null;
  multiSelectFilterText?: string | null;
  multiSelectSelectedText?: string | null;
  requiredFieldErrorMessage?: string | null;
  typeaheadNoResultsMessage?: string | null;
  characterLimitMessage?: string | null;
  submitToastMessage?: string | null;
  backButtonText?: string | null;
  nextButtonText?: string | null;
  restartButtonText?: string | null;
  submitButtonText?: string | null;
}

export interface Event {
  id: string;
}

export interface Pool {
  id: string;
}

export interface FormDefinitionBase {
  id: string;
  pages: Page[];
  modified: string;
  settings: FormSettingsBase;
  event?: Event;
  pool?: Pool | null;
  flexibleOptions: FlexibleOptions | null;
}

export interface PublicFormDefinition extends FormDefinitionBase {
  authenticated: boolean | null;
  companySettings: PublicFormCompanySettings;
}

export interface SavedFormDefinition extends FormDefinitionBase {
  status: 'published' | 'unpublished' | 'draft';
  settings: SavedFormSettings;
  companySettings: SavedFormCompanySettings;
  created: string;
  profile: FormUserProfile;
}
